/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from '@i18n';
import gqlSeller from '@sellermodules/storesetting/services/graphql';
import { additionalHelperMenu } from '@root/swift.config.js';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import LanguageSelect from '@modules/theme/layout/components/languageSelect';
import AppModal from '@common_appmodal/index';
import Button from '@common_button';
import Cookies from 'js-cookie';
import { custDataNameCookie } from '@config';
import { removeIsLoginFlagging } from '@helper_auth';
import loginGqlService from '@modules/login/services/graphql';
import Alert from '@common_alert';
import { miniDrawerWidthSeller, drawerWidthSeller } from '@modules/theme/layout/helpers';
import {
 PRIMARY, PRIMARY_DARK, TABLE_GRAY, BLACK, BORDER_COLOR, WHITE, LIGHT_GRAY, PURPLE,
} from '@theme_color';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@helper_localstorage';
import firebaseApp from '@lib_firebase/index';
import { isValidJSON } from '@helper_text';
import TextInfo from '@common_textinfo';

import addonService from '@sellermodules/storeintegration/services/graphql';
import nl2br from 'react-nl2br';
import { encrypt } from '@helper_encryption';
import themeService from '@modules/theme/services/graphql';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidthSeller,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidthSeller,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        border: '0',
        boxShadow: '2px 0px 20px #4D2F821A',
        justifyContent: 'space-between',
        '&.drawer-mobile': {
            width: '100%',
        },
    },
    masterDrawerOpen: {
        width: drawerWidthSeller,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        border: '0',
        boxShadow: '2px 0px 20px #4D2F821A',
        '&.drawer-mobile': {
            width: '100%',
        },
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: miniDrawerWidthSeller,
        },
        border: '0',
        boxShadow: '2px 0px 20px #4D2F821A',
    },
    togleMobile: {
        padding: '0 12px',
        '& button': {
            padding: '0px 8px 4px 8px',
        },
        '& h4': {
            display: 'inline-block',
        },
    },
    togleMenuButton: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: '16px',
        right: '16px',
    },
    togleMenuIcon: {
        fontSize: 30,
    },
    arrowBalance: {
        fontSize: 27,
        color: BLACK,
        transition: '0.3s',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    katalisLogo:{
        display: 'block',
        paddingTop: 15,
        marginLeft: 18,
    },
    swiftOmsLogo: {
        padding: 12,
        display: 'block',
        position: 'relative',
        minHeight: 'unset',
        marginBottom: 7,
        '& img': {
            height: 45,
            marginTop: 10,
        },
    },

    divMenu: {
        color: BLACK,
        '& .itemText span': {
            fontSize: 14,
            textTransform: 'capitalize',
        },
        justifyContent: 'center',
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 20,
        },
        '&.open': {
            '& .MuiListItemText-root': {
                flex: 'none',
            },
        },
        '&.close': {
            padding: '0 12px',
        },
    },
    menuList: {
        padding: 0,
        flex: '1 1 auto',
        '&.open': {
            padding: '0 12px',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
        },
    },
    menuItem: {
        marginTop: 15,
        borderRadius: 12,
        justifyContent: 'space-between',
        '&:hover': {
            background: TABLE_GRAY,
            color: PRIMARY,
            '& .itemIcon img': {
                filter: 'invert(31%) sepia(96%) saturate(7496%) hue-rotate(202deg) brightness(98%) contrast(106%)',
            },
        },
        '&.open .itemText': {
            flex: '1 1 auto',
        },
        '&.active': {
            background: TABLE_GRAY,
            color: PRIMARY,
            '& .itemIcon img': {
                filter: 'invert(31%) sepia(96%) saturate(7496%) hue-rotate(202deg) brightness(98%) contrast(106%)',
            },
            '& .itemText span': {
                fontWeight: 'bold',
            },
            '& .MuiSvgIcon-root': {
                rotate: '-90deg',
            },
        },
        '& .MuiSvgIcon-root': {
            transition: '0.3s',
        },
        '& .itemIcon img': {
            width: 20,
            height: 20,
        },
    },
    menuChildItem: {
        paddingLeft: 70,
        '&.active span': {
            color: PRIMARY,
            fontWeight: 'bold',
        },
        '&:hover span': {
            color: PRIMARY,
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    divMenuBalance: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        border: `1px solid ${BORDER_COLOR}`,
        padding: '10px',
        paddingBottom: '7px',
        color: PRIMARY_DARK,
        borderRadius: 4,
        '& .itemText': {
            fontSize: 14,
            textTransform: 'capitalize',
            display: 'inline-block',
            width: 132,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginLeft: 10,
        },
        '& .balance': {
            color: BLACK,
            fontWeight: 'bold',
            marginTop: -5,
            marginLeft: -12,
        },
        '&.close': {
            visibility: 'hidden',
        },
    },
    menuItemBalance: {
        '& .itemIcon': {
            width: 50,
            height: 50,
            border: '1px #ddd solid',
            padding: 2,
            borderRadius: 50,
        },
    },
    divOpenLogo: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        background: WHITE,
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.15)',
        border: `1px solid ${BORDER_COLOR}`,
        zIndex: 9,
        width: 'calc(100% - 24px)',
        borderRadius: '0 0 4px 4px',
        top: '85%',
        '& span': {
            padding: '10px 20px',
            fontWeight: 'bold',
        },
        '& span:hover': {
            background: LIGHT_GRAY,
        },
    },
    logoBottom: {
        paddingTop: 20,
        '& img': {
            width: 110,
        },
    },
    mobileUser: {
        padding: '0 12px',
        '& .user-list': {
            display: 'flex',
            borderRadius: 4,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 15px',
            marginTop: 15,
        },
        '& .user-icon': {
            marginRight: 20,
            display: 'inline-flex',
        },
        '& .item-icon': {
            width: 20,
            height: 20,
        },
        '& .user-text': {
            flex: '1 1 auto',
            '&._red': {
                color: '#F43030',
            }
        },
    },
    languageMobile: {
        display: 'inline-block',
        float: 'right',
        marginTop: 20,
        '& div > button': {
            fontSize: 13,
            padding: '4px 5px',
        },
    },
    subsButton: {
        display: 'inline-block',
        padding: '5px 10px 5px 10px',
        borderRadius: 5,
        marginTop: -2,
        fontSize: 13,
        minWidth: 80,
        background: '#F3F4FA',
        marginLeft: 10,
        fontWeight: 400,
        '& .p1': {
            margin: 0,
            marginTop: -8,
            fontWeight: 600,
            color: PRIMARY,
            fontSize: 10,       
            '& img': {
                width: 10,
                height: 10,
                marginRight: 3,
                position: 'relative',
                top: 0,
            }
        },
        '& .p2': {
            margin: 0,
            marginTop: 3,
            fontSize: 9,
            color: '#424242',
        }
    },
    hr: {
        border: 0,
        height: 1,
        background: BORDER_COLOR,
        marginBottom: 20,
    },
    headerMessage: {
        background: '#fff5db',
        border: '1px #fbd362 solid',
        color: BLACK,
        padding: '4px 10px 6px 10px',
        borderRadius: 5,
        marginTop: 10,
        marginBottom: -10,
        whiteSpace: 'normal',
    },
    addOnBox: {
        border: '1px #ddd solid',
        margin: 13,
        borderRadius: 5,
        padding: 10,
        marginTop: -5,
        background: '#FFF4FD',
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
        '& .header': {
            fontSize: 14,
            paddingTop: 5,
            '& img': {
                width: 18,
                height: 18,
            },
            '& strong': {
                position: 'relative',
                top: -3,
                left: 6,
            },
            '& div': {
                display: 'inline-block',
                marginLeft: 15,
                position: 'relative',
                top: -1,
                '& svg': {
                    width: 14,
                    height: 14,
                },
            },
        },
        '& .body': {
            paddingBottom: 5,
            fontSize: 13,
            borderTop: '1px #ddd solid',
            marginTop: 16,
            '& a:hover': {
                fontWeight: 600,
                color: PURPLE,
            },
            '& .MuiSvgIcon-root': {
                position: 'relative',
                top: 8,
            }
        },
    },
    smallText: {
        color: '#9f9f9f',
        fontSize: 13,
        margin: 0,
    },
    refresh: {
        marginLeft: 10,
        position: 'relative',
        top: -4,
        fontSize: 11,
        color: '#444',
        cursor: 'pointer',
    },
    icon: {
        width: 25,
        height: 25,
        position: 'relative',
        top: 4,
        marginRight: 5,
    },
    modalFooter: {
        marginTop: 20,
        '& .no': {
            marginRight: 10,
            background: WHITE,
            color: BLACK,
            border: '1px #ddd solid',
        },
    },
    billAlert: {
        marginBottom: -10,
    },
    billAlertContent: {
        textWrap: 'initial',
    },
    link: {
        fontSize: 10,
        color: '#8f9395',
        marginBottom: -10,
        '& a': {
            margin: 5,
        },
        '& a:hover': {
            color: BLACK,
        },
    },
}));

const Sidebar = ({
 activeParentMenu, setActiveParentMenu, activeChildMenu, setActiveChildMenu, open, setOpen, menuList, storeLogo, isShowSidebar, setIsOrderExced, setBillingMessage,
}) => {
    const router = useRouter();
    const classes = useStyles();
    const { t, i18n } = useTranslation('common');
    const handleClickParent = (menu) => {
        if (menu.key === (activeParentMenu && activeParentMenu.key)) {
            setActiveParentMenu(null);
        } else {
            setActiveParentMenu(menu);
            if (menu.url) {
                setOpen(false);
                router.push(menu.url);
            }
        }
    };
    const handleClickChild = (menu) => {
        setActiveChildMenu(menu);
        if (menu.url) {
            setOpen(false);
            router.push(menu.url);
        }
    };

    const aclDetail = getLocalStorage('acl') ? JSON.parse(getLocalStorage('acl')) : {};

    const [openSeller, setOpenSeller] = React.useState(false);
    const refOpenLogo = React.useRef(null);
    const handleClickLogoSeller = () => {
        setOpenSeller(!openSeller);
    };
    const handleOutsideClick = (e) => {
        if (refOpenLogo.current && !refOpenLogo.current.contains(e.target)) {
            setOpenSeller(false);
        }
    };
    React.useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const { data } = gqlSeller.getSeller();
    const { data: subscribtionData } = themeService.getBillingSubscription();
    

    React.useEffect(() => {
        const companyId = data?.getSeller?.id;
        if (companyId) {
            Cookies.set("web_init", encrypt(JSON.stringify({company_id: companyId})));
        }

        const subscribtion = subscribtionData?.getBillingSubscription || {};
        if (subscribtion?.is_subscription === false) {
            const credit = subscribtion?.remaining_credit || 0;
            setIsOrderExced(credit === 0);
        } else {
            const totalOrder = data?.getSeller?.summary_transaction?.total_order || 0;
            const limitOrder = data?.getSeller?.subscribtion_plan?.limit_order || 0;
            setIsOrderExced(limitOrder > 0 && totalOrder >= limitOrder);
        }
        
        setBillingMessage(data?.getSeller?.subscribtion_plan?.billing_message);
    }, [data, subscribtionData]);

    const endSubscribtion = () => {
        let endDate = data?.getSeller?.subscribtion_plan?.end_date;
        if (endDate) {
            endDate = endDate.split(' ');
            return endDate && endDate[0] ? endDate[0] : null;
        }
        return null;
    };

    const [removeCustomerToken] = loginGqlService.removeToken();
    const handleLogout = () => {
        try {
            const isActive = !!(sessionStorage.getItem("subAccount"));

            if (isActive) {
                sessionStorage.removeItem('subAccount');
                router.push('/seller/list');
            } else {
                sessionStorage.removeItem('is_parent');
                sessionStorage.removeItem('is_show_sidebar');
                firebaseApp.auth().signOut();
                removeCustomerToken()
                    .then(() => {
                        removeIsLoginFlagging();
                        Cookies.remove(custDataNameCookie);
                        router.push('/login');
                    })
                    .catch(() => {});
            }
        } catch (e) {}
    };


    const { data: dataKatalisCreditUrl } = loginGqlService.getStoreConfig({
        path: 'multitenant_katalis/general/purchase_credit_url',
    });

    const [getAddonsList, { data: addonList }] = addonService.getAddonsList();
    const [getKatalisCreditBalance] = addonService.getKatalisCreditBalance();
    const [getKatalisAccount] = addonService.getKatalisAccount();
    const [disconnectKatalisAccount] = addonService.disconnectKatalisAccount();
    const katalisTopupUrl = dataKatalisCreditUrl?.getStoreConfig;
    const getKatalisAddOnCookies = decodeURI(Cookies.get("katalis"));
    const [getKatalisAddOn, setKatalisAddOn] = React.useState(isValidJSON(getKatalisAddOnCookies) ? JSON.parse(getKatalisAddOnCookies) : {});
    const [katalisLoad, setKatalisLoad] = React.useState(false);
    const [addonListData] = (addonList?.getAddonsList?.items || []).filter((item) => item?.addon_code?.toLowerCase() === 'katalis');

    const disconnectKatalis = async (redirect = null) => {
        window.backdropLoader(true);
        setKatalisLoad(false);
        removeLocalStorage('is_katalis_expired');
        await disconnectKatalisAccount();
        Cookies.set("katalis", JSON.stringify({isConnect: false}));

        if (redirect) {
            router.push('/seller/saleschannels/storeintegration?activeTab=addon');
            return true;
        }

        router.reload();
        return true;
    };

    const updateKatalis = async () => {
        /* get addon information */
        setKatalisLoad(true);
        const loadAddonList = await getAddonsList();
        const addonList = loadAddonList?.data?.getAddonsList?.items || [];
        const [katalisAddon] = addonList?.filter((item) => item?.addon_code?.toLowerCase() === 'katalis');

        /* check addon statuss */
        if (Boolean(katalisAddon?.is_active) === true && katalisAddon?.is_connect) {
            const loadKatalisBalance = await getKatalisCreditBalance();
            const katalisBalance = loadKatalisBalance?.data?.getKatalisCreditBalance;

            if (loadKatalisBalance?.error?.message) {
                setLocalStorage('is_katalis_expired', '1');
                return false;
            }
            
            const isConnectedKatalisStatus = typeof katalisBalance === "undefined" || katalisBalance === '' ? false : katalisBalance;
            const loadKatalisAccount = await getKatalisAccount();
            const katalisAccount = loadKatalisAccount?.data?.getKatalisAccount;

            /* generate key secret for billing topup */
            const generateData = encrypt(JSON.stringify({                    
                vendor_id: katalisAccount?.vendor_id,
                addon: katalisAddon?.addon_code?.toLowerCase(),
                type: "email",
                value: katalisAccount?.email,
            }));

            /* generate addon info */
            const getAddonListData = {
                is_enable: katalisAddon?.is_active,
                title: katalisAddon?.addon_name,
                name: katalisAddon?.addon_code,
                icon: katalisAddon?.logo,
                features: katalisAddon?.features,
                ...(isValidJSON(katalisAddon?.additional_config) ? JSON.parse(katalisAddon?.additional_config) : [])
            };

            /* set katalis information */
            Cookies.set("katalis", JSON.stringify({
                email: katalisAccount?.email,
                balance: isConnectedKatalisStatus !== false ? isConnectedKatalisStatus : 0,
                billingUrl: `${katalisTopupUrl}?secret=${generateData}`,
                detail: getAddonListData,
                isConnect: true,
            }));
        } else {
            setLocalStorage('is_katalis_expired', '1');
            return false;
        }

        setKatalisLoad(false);
    };

    React.useEffect(async () => {
        await getAddonsList();
    }, []);

    const reloadCookie = async () => {
        try {
            const getKatalisCookies = decodeURI(Cookies.get("katalis"));
            if (isValidJSON(getKatalisCookies)) {
                const katalis = JSON.parse(getKatalisCookies);
                setKatalisAddOn(katalis);
            }
        } catch (error) {
            setKatalisAddOn({});
        }

        setTimeout(() => reloadCookie(), 1000);
    };

    React.useEffect(() => reloadCookie(), [Cookies.get("katalis")]);

    const [company] = React.useState({});
    React.useEffect(() => {
        company.is_parent = !!(parseInt(sessionStorage.getItem('is_parent')));
        company.is_sub_account = !!(parseInt(sessionStorage.getItem('subAccount')));
        company.is_show_sidebar = !!(parseInt(sessionStorage.getItem('is_show_sidebar')));
    }, []);

    const styleStatus = () => {
        if (company.is_parent) {
            if (company.is_sub_account && isShowSidebar) {
                return false;
            } else {
                return true;
            }
        } else if (!isShowSidebar) {
            return true;
        }
        return false;
    };

    const SidebarContent = () => (
        <>
            <Hidden mdUp>
                <div className={classes.togleMobile}>
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => setOpen(false)}>
                        <CloseIcon className={classes.togleMenuIcon} />
                    </IconButton>
                    <h4>{t('common:menu_utama')}</h4>
                    <div className={classes.languageMobile}>
                        <LanguageSelect color={PRIMARY_DARK} />
                    </div>
                </div>
            </Hidden>
            <div
                className={clsx(classes.toolbar, classes.katalisLogo, open ? 'open' : 'close')}
                onClick={() => router.push('/')}
                onKeyDown={() => router.push('/')}
            >
                <img
                    alt=""
                    src={'/assets/img/katalis_logo.png'}
                />
            </div>
            <div className={clsx(classes.toolbar, classes.swiftOmsLogo, 'open')} onClick={() => handleClickLogoSeller()} ref={refOpenLogo}>
                <div className={clsx(classes.divMenuBalance, 'open')}>
                    <div className={classes.menuItemBalance}>
                        <img
                            className="itemIcon"
                            alt=""
                            src={data?.getSeller?.logo !== '' ? data?.getSeller?.logo : '/assets/img/swiftoms_logo_collapsed.png'}
                        />
                    </div>
                    <div className="balance">
                        <span className="itemText" style={{ fontSize: 13 }}>{data?.getSeller?.name}</span>
                        <br />
                        { data?.getSeller?.subscribtion_plan?.subscribtion_name && (
                            <a href={data?.getSeller?.subscribtion_plan?.service_information_url || '#'} target={data?.getSeller?.subscribtion_plan?.service_information_url && '_blank'}>
                                <div className={classes.subsButton}>
                                    <p class="p1">
                                        <img src="/assets/img/star.png" />
                                        { data?.getSeller?.subscribtion_plan?.subscribtion_name }
                                    </p>
                                    {endSubscribtion() && (
                                        <p class="p2">Valid until : { endSubscribtion() }</p>
                                    )}
                                </div>
                            </a>
                        )}
                    </div>
                </div>
                {data?.getSeller?.subscribtion_plan?.billing_message && (
                    <Hidden mdUp>
                        <Alert
                            containerClassName={classes.billAlert}
                            contentClassName={classes.billAlertContent}
                            severity="warning"
                            titleMultiple={[
                                {
                                    title: `${t('bill_reminder')}`,
                                    info: <span dangerouslySetInnerHTML={{ __html: data?.getSeller?.subscribtion_plan?.billing_message }} />,
                                },
                            ]}
                        />
                    </Hidden>
                )}
            </div>
            
            { Boolean(addonListData?.is_active) && getKatalisAddOn?.isConnect ? (
                <div className={classes.addOnBox}>
                    <div className="header">                                       
                        <img src={getKatalisAddOn?.detail?.icon} />
                        <strong>{t('credit')}: { getKatalisAddOn?.balance }</strong>
                        { getKatalisAddOn?.detail?.tooltip?.[i18n?.language] && (
                            <TextInfo
                                textHelp={
                                    <p style={{ textAlign: 'left', padding: 10, marginTop: -10, marginBottom: -10, paddingTop: 20, paddingBottom: 20 }}>
                                        {nl2br(getKatalisAddOn?.detail?.tooltip?.[i18n?.language])}
                                    </p>
                                }
                                textHelpPlacement="top"
                                size={16}
                            />
                        )}

                        { katalisLoad === true && <img style={{marginLeft: 10}} src="/assets/img/loader.gif" /> }
                        { katalisLoad === false && (<span className={classes.refresh} onClick={() => updateKatalis() }>[{t('refresh')}]</span>)}
                        <p className={classes.smallText}>{getKatalisAddOn?.email}</p>
                    </div>
                    <div className="body">
                        <a href={getKatalisAddOn?.billingUrl} target="_blank">{t('get_more_credits')} <ChevronRightIcon /></a>
                    </div>
                </div>
            ) : ''}

            { !styleStatus() && (
                <List className={clsx(classes.menuList, open ? 'open' : 'close')}>
                    {menuList?.map((menu) => {
                        const isActiveMenu = aclDetail && aclDetail?.acl_code?.includes(menu.aclCode) && !menu.hide;
                        return (
                            <div key={menu.key}>
                                { isActiveMenu && (
                                    <div
                                        id={menu.key === 'catalog' ? 'guide-catalog' : menu.key === 'channelseller' ? 'guide-sales' : null}
                                        className={clsx(classes.divMenu, open ? 'open' : 'close')}
                                        key={menu.key}
                                    >
                                        <Link href={menu.url || '#'} key={menu.key}>
                                            <a onClick={(e) => e.preventDefault()}>
                                                <ListItem
                                                    button
                                                    className={clsx(
                                                        classes.menuItem,
                                                        open ? 'open' : 'close',
                                                        menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                                    )}
                                                    onClick={() => handleClickParent(menu)}
                                                >
                                                    <ListItemIcon className="itemIcon">
                                                        <img alt="" src={`/assets/img/layout/seller/${menu.key}.svg`} />
                                                    </ListItemIcon>
                                                    <ListItemText className="itemText" primary={menu.label} />
                                                    {menu?.children?.length > 0 && <ChevronRightIcon />}
                                                </ListItem>
                                            </a>
                                        </Link>
                                        {menu && menu.children && menu.children.length && (
                                            <Collapse in={activeParentMenu && activeParentMenu.key === menu.key} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {menu.children.map((menuChild) => (
                                                        <div key={menuChild.key}>
                                                            {((aclDetail && aclDetail.acl_code.includes(menuChild.aclCode)) || menuChild.notInAcl)
                                                                && !menuChild.hide && (
                                                                    <Link href={menuChild.url} key={menuChild.key}>
                                                                        <a>
                                                                            <ListItem
                                                                                button
                                                                                key={menuChild.key}
                                                                                className={clsx(
                                                                                    classes.menuChildItem,
                                                                                    menuChild.key === (activeChildMenu && activeChildMenu.key) && 'active',
                                                                                )}
                                                                                onClick={() => handleClickChild(menuChild)}
                                                                            >
                                                                                <ListItemText className="itemText" primary={menuChild.label} />
                                                                            </ListItem>
                                                                        </a>
                                                                    </Link>
                                                                )}
                                                        </div>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </List>
            )}
            
            <Hidden smUp>
                <div className={classes.mobileUser}>
                    { !styleStatus() && (
                        <hr className={classes.hr} />
                    )}
                    { styleStatus() && (
                        <>
                            <div className="user-list">
                                <div className="user-icon">
                                    <img className="item-icon" alt="" src="/assets/img/layout/seller/dashboard.svg" />
                                </div>
                                <Link href="/seller/list">
                                    <div className="user-text">
                                        <a className="linkOut">{t('Seller_List')}</a>
                                    </div>
                                </Link>
                            </div>
                            <div className="user-list">
                                <div className="user-icon">
                                    <img className="item-icon" alt="" src="/assets/img/layout/seller/storesetting.svg" />
                                </div>
                                <Link href="/seller/storesetting">
                                    <div className="user-text">
                                        <a className="linkOut">{t('common:profile_brand')}</a>
                                    </div>
                                </Link>
                            </div>
                        </>
                    )}
                    <div className="user-list">
                        <div className="user-icon">
                            <img className="item-icon" alt="" src="/assets/img/layout/avatar.svg" />
                        </div>
                        <Link href="/seller/account">
                            <div className="user-text">
                                <a className="linkOut">{t('common:Edit_Profile')}</a>
                            </div>
                        </Link>
                    </div>
                    <div className="user-list">
                        <div className="user-icon">
                            <img className="item-icon" alt="" src="/assets/img/logout.svg" />
                        </div>
                        <div className="user-text _red">
                            <a className="linkOut" href="#" onClick={handleLogout}>
                                {t('common:Sign_Out')}
                            </a>
                        </div>
                    </div>
                </div>
            </Hidden>
            <div style={{ padding: '20px 30px', textAlign: 'center' }}>
                <div className={classes.logoBottom}>
                    <img alt="logo oms" src={storeLogo?.logo || '/assets/img/logo-seller.svg'} />
                    <div className={classes.link}>
                        {additionalHelperMenu.map((i) => (
                            <Link href={i?.url}>
                                <a target={i?.is_new_tab && '_blank'}>
                                    {i18n?.language === 'en' ? i?.t_en : i?.t_id}
                                </a>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );

    const changeClasses = () => {
        if (company.is_parent && !company.is_sub_account) {
            return classes.masterDrawerOpen;
        }

        return classes.drawerOpen;
    }

    const SidebarMobile = () => (
        <Drawer
            variant="temporary"
            open={open}
            onClose={() => setOpen(false)}
            className={clsx(classes.drawer, 'drawer-mobile', open ? changeClasses() : classes.drawerClose)}
            classes={{
                paper: clsx('drawer-mobile', open ? changeClasses() : classes.drawerClose),
            }}
            ModalProps={{ keepMounted: true }}
            style={{ justifyContent: 'none' }}
        >
            {SidebarContent()}
        </Drawer>
    );

    const SidebarDesktop = () => (
        <Drawer
            variant="permanent"
            open={open}
            className={clsx(classes.drawer, classes.drawerOpen)}
            classes={{
                paper: classes.drawerOpen,
            }}
        >
            {SidebarContent()}
        </Drawer>
    );

    return (
        <>
            <div id="sidebar">
                <Hidden mdUp>{SidebarMobile()}</Hidden>
                { !styleStatus() && <Hidden smDown>{SidebarDesktop()}</Hidden>}
            </div>

            <AppModal
                className={classes.modal}
                title={(
                    <center>
                        <img src="/assets/img/katalis.png" className={classes.icon} />
                        {t('Your_session_has_expired')}
                    </center>
                )}
                show={getLocalStorage('is_katalis_expired')}
                onHandleClose={() => false }
            >
                <div className={classes.modalContainer}>
                    {t('Your_Katalis_AI_session_has_ended_Please_re_integrate_your_Katalis_account_to_continue_using_it')}
                    <div className={classes.modalFooter}>
                        <Button onClick={() => disconnectKatalis()} className='no'>{t('Close')}</Button>
                        <Button onClick={() => disconnectKatalis(true)}>{t('Reintegrate')}</Button>
                    </div>
                </div>
            </AppModal>
        </>
    );
};

export default Sidebar;
